import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SaveIcon from '@material-ui/icons/Save'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import { DateTime } from 'luxon'
import React from 'react'
import { createSearchParams } from 'react-router-dom'
import { useAsync, useAsyncFn, useCopyToClipboard, useUnmount } from 'react-use'
import { XYWH } from '../../../types'
import { extendClip } from '../../api/ClipExtended'
import { Clip } from '../../api/codegen/typescript-axios'
import { validateXYWH } from '../../helpers/validateXYWH'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { ProgressButton } from '../FormInputs/ProgressButton'
import { useProject } from '../ProjectWrapper'
import { useAnalysesStore } from './AnalysesController'
import { useClipsStore } from './VODClipsController'
import { getUseVODStore } from './VODController'

export const VODClipEditor = ({}: {}) => {
  const api = useApi()
  const project = useProject()
  const acp = useAlertConfirmPrompt()
  const [, copyToClipboard] = useCopyToClipboard()
  const useVODStore = getUseVODStore()
  const analysesStore = useAnalysesStore()
  const clipsStore = useClipsStore()

  const gotoVideo = useVODStore((state) => state.gotoVideo)

  const analysisId = analysesStore((state) => state.analysisId)

  const clip = clipsStore((state) => state.clip)
  const clipId = clipsStore((state) => state.clipId)

  const setClip = (_clip?: Clip) => {
    clipsStore.setState({
      clip: _clip ? extendClip(_clip, project) : undefined,
    })
  }

  useUnmount(() => setClip(undefined))

  const [saveClipState, saveClip] = useAsyncFn(async () => {
    if (!analysisId || !clip) return
    const resp = await api.videoAnnotationsApi.videoannotationAnalysesClipsPartialUpdate(
      {
        id: clip.id!.toString(),
        analysisId: analysisId.toString(),
        data: clip,
      }
    )
    setClip(resp.data)
    clipsStore.getState().fetchClips()
  }, [analysisId, clip])

  const [deleteClipState, deleteClip] = useAsyncFn(async () => {
    if (!analysisId || !clipId) return
    await acp.confirm({
      title: 'Are you sure you want to delete this clip?',
    })
    await api.videoAnnotationsApi.videoannotationAnalysesClipsDelete({
      analysisId: analysisId.toString(),
      id: clipId.toString(),
    })
    setClip()
    clipsStore.getState().fetchClips()
  }, [analysisId, clipId])

  return (
    <>
      {clip && (
        <>
          {/* Stream */}
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Stream</InputLabel>
              <Select
                value={clip.stream || 0}
                onChange={(e) => {
                  setClip({
                    ...clip,
                    stream: parseInt(e.target.value as string),
                  })
                }}
                label="Stream"
              >
                <MenuItem key={'undefined'} value={0}>
                  Select stream
                </MenuItem>
                {project.streams.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Start Datetime */}
          <Grid item xs={12}>
            <OutlinedInput
              type={'text'}
              label="Start"
              value={clip.start_datetime || ''}
              onChange={(e) => {
                try {
                  const dt = DateTime.fromISO(e.target.value)
                  setClip({
                    ...clip,
                    start_datetime: e.target.value,
                  })
                } catch {
                  console.error('Invalid datetime')
                }
              }}
              margin="dense"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Copy clock time to input"
                    onClick={() => {
                      setClip({
                        ...clip,
                        start_datetime: useVODStore
                          .getState()
                          .videoDateTime.startOf('second')
                          .toISO({ suppressMilliseconds: true }),
                      })
                    }}
                    edge="end"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </Grid>

          {/* End Datetime */}
          <Grid item xs={12}>
            <OutlinedInput
              type={'text'}
              label="End"
              value={clip.end_datetime || ''}
              onChange={(e) => {
                try {
                  const dt = DateTime.fromISO(e.target.value)
                  setClip({
                    ...clip,
                    end_datetime: e.target.value,
                  })
                } catch {
                  console.error('Invalid datetime')
                }
              }}
              margin="dense"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Copy clock time to input"
                    onClick={() => {
                      setClip({
                        ...clip,
                        end_datetime: useVODStore
                          .getState()
                          .videoDateTime.startOf('second')
                          .toISO({ suppressMilliseconds: true }),
                      })
                    }}
                    edge="end"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </Grid>

          {/* Speed */}
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Speed</InputLabel>
              <Select
                value={clip.speed}
                onChange={(e) => {
                  setClip({
                    ...clip,
                    speed: parseInt(e.target.value as string),
                  })
                }}
                label="Speed"
              >
                {[1, 2, 4, 10, 20, 50, 100, 200].map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Crop */}
          <Grid item xs={12} container spacing={1} alignItems="center">
            {['x', 'y', 'w', 'h'].map((n) => (
              <Grid item key={n} xs={3}>
                <TextField
                  label={n}
                  type="number"
                  value={
                    typeof clip[n as keyof XYWH] === 'number'
                      ? clip[n as keyof XYWH]
                      : ''
                  }
                  onChange={(e) =>
                    setClip({
                      ...clip,
                      [n as keyof XYWH]: parseInt(e.target.value),
                    })
                  }
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            ))}
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  gotoVideo({
                    crop: validateXYWH(clip),
                  })
                }}
                disabled={!validateXYWH(clip)}
                endIcon={<ZoomInIcon />}
              >
                Goto
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setClip({
                    ...clip,
                    ...useVODStore.getState().videoViewport,
                  })
                }}
                endIcon={<FileCopyIcon />}
              >
                Copy
              </Button>
            </Grid>
          </Grid>

          {/* Title */}
          <Grid item xs={12}>
            <TextField
              label="Title"
              value={clip.title || ''}
              onChange={(e) =>
                setClip({
                  ...clip,
                  title: e.target.value,
                })
              }
              margin="dense"
              fullWidth
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={clip.description || ''}
              onChange={(e) =>
                setClip({
                  ...clip,
                  description: e.target.value,
                })
              }
              margin="dense"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>

          {/* Grouping */}
          <Grid item xs={12}>
            <TextField
              label="Grouping"
              value={clip.grouping || ''}
              onChange={(e) =>
                setClip({
                  ...clip,
                  grouping: e.target.value,
                })
              }
              margin="dense"
              fullWidth
              variant="outlined"
            />
          </Grid>

          {/* Featured */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={clip.featured}
                  onChange={(e) =>
                    setClip({
                      ...clip,
                      featured: e.target.checked,
                    })
                  }
                  name="featured"
                  color="primary"
                />
              }
              label="Featured"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                gotoVideo({
                  streamId: clip.stream,
                  dateTime: DateTime.fromISO(clip.start_datetime, {
                    zone: project.timezone,
                  })
                    // HACK! add random milliseconds to change the URL, forcing a video seek
                    .plus({ milliseconds: Math.random() * 1000 }),
                  speed: clip.speed,
                  endDateTime: clip.end_datetime
                    ? DateTime.fromISO(clip.end_datetime, {
                        zone: project.timezone,
                      })
                    : undefined,
                  play: true,
                })
              }}
              endIcon={<PlayArrowIcon />}
            >
              Play
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (!clipId) return
                const searchParams = createSearchParams()
                searchParams.set('clip', clipId.toString())
                const search = searchParams.toString()
                copyToClipboard(
                  `${window.location.origin}${window.location.pathname}?${search}`
                )
              }}
              endIcon={<FileCopyIcon />}
            >
              Copy URL
            </Button>
          </Grid>

          <Grid item xs={12}>
            <ProgressButton
              variant="contained"
              fullWidth
              fetchState={saveClipState}
              onClick={saveClip}
              endIcon={<SaveIcon />}
            >
              Save
            </ProgressButton>
          </Grid>
          <Grid item xs={12}>
            <ProgressButton
              variant="contained"
              fullWidth
              fetchState={deleteClipState}
              onClick={deleteClip}
              endIcon={<DeleteIcon />}
            >
              Delete
            </ProgressButton>
          </Grid>
        </>
      )}
    </>
  )
}
